import React, { useEffect, useMemo } from 'react';
import BaseTextInput from '@/components/Core/Form/BaseTextInput';
import { useForm, useWatch } from 'react-hook-form';
import { Divider } from 'primereact/divider';
import { clsx } from 'clsx';
import { z } from 'zod';
import { Button } from 'primereact/button';
import { zodResolver } from '@hookform/resolvers/zod';
import BaseDropdown from '@/components/Core/Form/BaseDropdown';
import { Address, CustomerTypeEnum } from '@/stub';
import { capitalize } from '@/Util/capitalize';
import BaseCheckbox from '@/components/Core/Form/BaseCheckbox';
import { useValidationErrors } from "@/Hooks/useValidationErrors";
import VulnerableCategorySelect from "@/components/Core/Form/Selector/VulnerableCategorySelect";
import CustomerAddress from "@/components/Core/Form/Selector/CustomerAddress";

const customerTitleOptions = [
    {
        label: 'Mr',
        value: 'mr',
    },
    {
        label: 'Mrs',
        value: 'mrs',
    },
    {
        label: 'Miss',
        value: 'miss',
    },
    {
        label: 'Ms',
        value: 'ms',
    },
    {
        label: 'Mx',
        value: 'mx',
    },
    {
        label: 'Dr',
        value: 'dr',
    },
    {
        label: 'Sir',
        value: 'sir',
    },
    {
        label: 'Madam',
        value: 'madam',
    },
    {
        label: 'Lord',
        value: 'lord',
    },
    {
        label: 'Lady',
        value: 'lady',
    },
    {
        label: 'Prof',
        value: 'prof',
    },
    {
        label: 'Cpt',
        value: 'cpt',
    },
];

const CustomerInfoFormSchema = z.object({
    id: z.number().nullish(),
    title: z.string().optional(),
    type: z.nativeEnum(CustomerTypeEnum),
    is_hec: z.boolean(),
    is_vulnerable: z.boolean(),
    vulnerable_category_id: z.coerce.number().nullish(),
    firstname: z.string(),
    middlename: z.string().nullish(),
    surname: z.string().nullish(),
    id_number: z.string(),
    email: z.string().email('This is not a valid email'),
    phone: z.string().nullish(),
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    postcode: z.string().nullish(),
    county: z.string().nullish(),
    city: z.string().nullish(),
    country: z.string().nullish()
}).superRefine((data, ctx) => {
    if (!data.id_number) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: data.type === CustomerTypeEnum.Person ? 'Customer ID' : 'Organisation ID' + ' is required',
            path: ['id_number']
        });
    }
    if (!data.firstname) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: data.type === CustomerTypeEnum.Person ? 'First Name' : 'Name' + ' is required',
            path: ['firstname']
        });
    }
    if (data.type === CustomerTypeEnum.Person) {
        if (!data.title) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Title is required',
                path: ['title']
            });
        }
        if (!data.surname) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Surname is required',
                path: ['surname']
            });
        }
        if (!data.address1) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Address 1 is required',
                path: ['address1']
            });
        }
        if (!data.city) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'City is required',
                path: ['city']
            });
        }
        if (!data.county) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'County is required',
                path: ['county']
            });
        }
        if (!data.country) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Country is required',
                path: ['country']
            });
        }
    }
});

export type CustomerInfoFormData = z.infer<typeof CustomerInfoFormSchema>;

export type CustomerInfoInitData = Partial<CustomerInfoFormData>;

export type CustomerFormProps = {
    className?: string
    availableCustomerTypes?: CustomerTypeEnum[]
    onSubmit?: (data: CustomerInfoFormData) => void
    onSubmitButtonLabel?: string
    onSubmitButtonDisabled?: boolean
    isProcessing?: boolean
    initFormData?: CustomerInfoInitData
    errorBag?: Error[]
};

const CustomerForm: React.FC<CustomerFormProps> = ({
    className,
    availableCustomerTypes,
    onSubmit,
    isProcessing,
    initFormData,
    errorBag,
    onSubmitButtonLabel,
    onSubmitButtonDisabled = false

}: CustomerFormProps) => {
    const defaultFormValues = useMemo<CustomerInfoFormData>(() => {
        let defaultCustomerType: CustomerTypeEnum = CustomerTypeEnum.Person;
        if (
            availableCustomerTypes
            && availableCustomerTypes?.length !== 0
        ) {
            defaultCustomerType = availableCustomerTypes[0];
        }
        return {
            id: 0,
            type: defaultCustomerType,
            is_hec: false,
            is_vulnerable: false,
            vulnerable_category_id: null,
            title: '',
            firstname: '',
            middlename: '',
            surname: '',
            id_number: '',
            email: '',
            phone: '',
            address1: '',
            address2: '',
            postcode: '',
            county: '',
            city: '',
            country: ''
        };
    }, [availableCustomerTypes]);

    const {
        control,
        setValue,
        setError,
        resetField,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm<CustomerInfoFormData>({
        resolver: zodResolver(CustomerInfoFormSchema),
        defaultValues: { ...defaultFormValues, ...initFormData }
    });

    const isVulnerable = useWatch({
        control,
        name: 'is_vulnerable'
    });

    const customerType = useWatch({
        control,
        name: 'type'
    });

    useEffect(() => {
        if (!isVulnerable) {
            resetField('vulnerable_category_id');
        }
    }, [isVulnerable, resetField]);

    useValidationErrors(setError, errorBag);

    const customerTypeOptions = useMemo(() => {
        const customerTypes = availableCustomerTypes ?? (Object.values(CustomerTypeEnum) as CustomerTypeEnum[]);
        const customerTypeOptions = customerTypes.map((type) => {
            return {
                label: capitalize(type),
                value: type
            };
        });
        if (customerTypeOptions.length === 1) {
            setValue('type', customerTypeOptions[0].value);
        }
        return customerTypeOptions;
    }, [availableCustomerTypes, setValue]);

    const onFormSubmit = (data: CustomerInfoFormData) => {
        if (onSubmit) {
            onSubmit(data);
        }
    };

    const onAddressSelect = (address: Address) => {
        const { id, ...addressContent } = address;
        reset((prev) => ({ ...prev, ...addressContent }));
    };

    return (
        <div className={clsx(className, 'customer-form')}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="grid">
                    <BaseDropdown
                        className="col-4"
                        control={control}
                        required
                        name="type"
                        label="Customer type"
                        options={customerTypeOptions}
                        errorMessages={errors.type?.message}
                    />
                    {customerType === CustomerTypeEnum.Person && <BaseCheckbox
                        className="col-2"
                        control={control}
                        name="is_hec"
                        checked={false}
                        label="HEC"
                        errorMessages={errors.is_hec?.message}
                    />}
                    {customerType === CustomerTypeEnum.Person && <BaseCheckbox
                        className="col-2"
                        control={control}
                        name="is_vulnerable"
                        checked={false}
                        label="Vulnerable"
                        errorMessages={errors.is_vulnerable?.message}
                    />}
                    {
                        customerType === CustomerTypeEnum.Person && isVulnerable && <VulnerableCategorySelect
                            className="flex-column col-8"
                            control={control}
                            name="vulnerable_category_id"
                            label="Vulnerable Category"
                            errorMessages={errors.vulnerable_category_id?.message}
                        />
                    }
                    <Divider className="col-12"/>
                    {customerType === CustomerTypeEnum.Person && <BaseDropdown
                        className="col-6"
                        control={control}
                        name="title"
                        label="Title"
                        required={customerType === CustomerTypeEnum.Person}
                        options={customerTitleOptions}
                        errorMessages={errors.title?.message}
                    />}
                    <BaseTextInput
                        className="col-6"
                        control={control}
                        required
                        name="firstname"
                        label={customerType === CustomerTypeEnum.Person ? 'First Name' : 'Name'}
                        errorMessages={errors.firstname?.message}
                    />
                    {customerType === CustomerTypeEnum.Person && <BaseTextInput
                        className="col-6"
                        control={control}
                        name="middlename"
                        label="Middle Name"
                        errorMessages={errors.middlename?.message}
                    />}
                    {customerType === CustomerTypeEnum.Person && <BaseTextInput
                        className="col-6"
                        control={control}
                        required={customerType === CustomerTypeEnum.Person}
                        name="surname"
                        label="Surname"
                        errorMessages={errors.surname?.message}
                    />}
                    <Divider className="col-12"/>
                    <BaseTextInput
                        className="col-6"
                        control={control}
                        required
                        name="id_number"
                        label={customerType === CustomerTypeEnum.Person ? 'Customer ID' : 'Organisation ID'}
                        errorMessages={errors.id_number?.message}
                    />
                    <BaseTextInput
                        className="col-6"
                        control={control}
                        required
                        name="email"
                        label="Email"
                        errorMessages={errors.email?.message}
                    />
                    <BaseTextInput
                        className="col-12"
                        control={control}
                        name="phone"
                        label="Phone Number"
                        errorMessages={errors.phone?.message}
                    />
                    <Divider className="col-12"/>
                    <CustomerAddress
                        control={control}
                        onAddressSelect={onAddressSelect}
                        errorMessages={errors}
                    />
                </div>
                <div className="flex">
                    <Button
                        className="ml-auto"
                        label={onSubmitButtonLabel}
                        disabled={onSubmitButtonDisabled}
                        type="submit"
                        severity="success"
                        loading={isProcessing}
                    />
                </div>
            </form>
        </div>
    );
};

export default CustomerForm;
