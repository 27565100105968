/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActivityLogEvent,
  GetActivityLogList200Response,
  SortBy,
  SortOrder,
} from '../models/index';
import {
    ActivityLogEventFromJSON,
    ActivityLogEventToJSON,
    GetActivityLogList200ResponseFromJSON,
    GetActivityLogList200ResponseToJSON,
    SortByFromJSON,
    SortByToJSON,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models/index';

export interface GetActivityLogListRequest {
    search?: string;
    limit?: number;
    page?: number;
    causer_id?: number;
    subject_id?: number;
    event?: ActivityLogEvent;
    log_name?: GetActivityLogListLogNameEnum;
    date_from?: string;
    date_to?: string;
    sort_order?: SortOrder;
    sort_by?: SortBy;
}

/**
 * 
 */
export class ActivityLogApi extends runtime.BaseAPI {

    /**
     * Get list of activity-log
     */
    async getActivityLogListRaw(requestParameters: GetActivityLogListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetActivityLogList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.causer_id !== undefined) {
            queryParameters['causer_id'] = requestParameters.causer_id;
        }

        if (requestParameters.subject_id !== undefined) {
            queryParameters['subject_id'] = requestParameters.subject_id;
        }

        if (requestParameters.event !== undefined) {
            queryParameters['event'] = requestParameters.event;
        }

        if (requestParameters.log_name !== undefined) {
            queryParameters['log_name'] = requestParameters.log_name;
        }

        if (requestParameters.date_from !== undefined) {
            queryParameters['date_from'] = requestParameters.date_from;
        }

        if (requestParameters.date_to !== undefined) {
            queryParameters['date_to'] = requestParameters.date_to;
        }

        if (requestParameters.sort_order !== undefined) {
            queryParameters['sort_order'] = requestParameters.sort_order;
        }

        if (requestParameters.sort_by !== undefined) {
            queryParameters['sort_by'] = requestParameters.sort_by;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activity-log`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetActivityLogList200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of activity-log
     */
    async getActivityLogList(requestParameters: GetActivityLogListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetActivityLogList200Response> {
        const response = await this.getActivityLogListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetActivityLogListLogNameEnum = {
    Old: 'old',
    ComplaintAction: 'complaint_action',
    ComplaintContact: 'complaint_contact',
    ComplaintFos: 'complaint_fos',
    ComplaintCustomer: 'complaint_customer',
    ComplaintPoint: 'complaint_point',
    Complaint: 'complaint',
    ComplaintDecision: 'complaint_decision',
    ComplaintLetter: 'complaint_letter',
    ComplaintDocument: 'complaint_document',
    ComplaintEvidence: 'complaint_evidence',
    ComplaintOutcomes: 'complaint_outcomes',
    User: 'user'
} as const;
export type GetActivityLogListLogNameEnum = typeof GetActivityLogListLogNameEnum[keyof typeof GetActivityLogListLogNameEnum];
