import { Column, ColumnBodyOptions } from 'primereact/column';
import React, { useState, useMemo } from 'react';
import { DT_ROWS_NO } from '@/config/constants';
import styled from 'styled-components';
import {
    DataTable,
    type DataTablePassThroughOptions,
    type DataTableStateEvent
} from 'primereact/datatable';
import {
    type ActivityLog, ComplaintAudit, GetActivityLogListRequest,
    SortOrder as SortOrderAudit,
    GetUsersFromOrganisationRequest, UpdateUserRolesRequestAdditionalRolesEnum,
    UpdateUserRolesRequestMainRoleEnum,
} from '@/stub';
import { SortOrder } from 'primereact/api';
import { produce } from 'immer';
import AnglesSort from '@/components/Icons/AnglesSort';
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { useGetActivityLog } from "@/Service/Api/ApiHooks/ActivityLog/useGetActivityLog";
import { getLabelForState } from "@/components/Datatables/ComplaintsDatatable";
import { getLabelForLogsEvent, getLabelForType } from "@/components/Filters/Audit/AuditFilterList";
import useGetAuditFiltersQuery, { AuditFilterType } from "@/Hooks/useGetAuditFiltersQuery";
import { useUpdateSearchParams } from "@/Hooks/useUpdateSearchParams";
import { Dialog } from "primereact/dialog";
import AuditPreview from "@/components/Core/Audit/AuditPreview";
import { formatToUKDate } from "@/Util/formatToUKDate";

type TableFilterOptions = {
    first: number
    rows: number
    sortField: string
    sortOrder: SortOrder
    search: string
};

type TableColumnDefinition = {
    label: string
    sortable?: boolean
    field?: string
    sortField?: string
    headerTooltip?: string
    body?: React.ReactNode | ((data: ActivityLog, options: ColumnBodyOptions) => React.ReactNode)
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .content-header {
        padding: 1rem;
        border-bottom: var(--gray-300) 1px solid;

        &__title {
            margin: 0.25rem 0;
            font-weight: 600;
            font-size: 1.5rem;
        }

        &__description {
            font-size: 0.9rem;
            color: var(--gray-500);
        }
    }

    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .datatable-container {
        margin-top: 2rem;
    }

    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .datatable-cell {
        &__clean-text {
            margin: 0;
        }
    }

    .angles-sort {
        margin-left: 0.3rem;

        &__icon {
            color: var(--primary-200);
            font-size: 0.8rem;
            font-weight: 600;

            &.active {
                color: var(--primary-500);
            }
        }
    }
`;

const getSortOrder = (sortOrderQuery: string | number | null): SortOrder => {
    const order = Number(sortOrderQuery);
    if (order === 1 || order === -1) {
        return order as SortOrder;
    }
    return -1;
};

const roleMappings: { [key: string]: string } = {
    ...Object.entries(UpdateUserRolesRequestMainRoleEnum).reduce((acc, [key, value]) => {
        acc[value] = key.replace(/([A-Z])/g, ' $1').trim();
        return acc;
    }, {} as { [key: string]: string }),
    ...Object.entries(UpdateUserRolesRequestAdditionalRolesEnum).reduce((acc, [key, value]) => {
        acc[value] = key.replace(/([A-Z])/g, ' $1').trim();
        return acc;
    }, {} as { [key: string]: string }),
};

export const formatRole = (role: string): string => {
    return roleMappings[role] || role.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
};

export type OrganisationAuditDatableProps = {
    requestParams?: Partial<GetUsersFromOrganisationRequest>
};

/**
 * OrganisationAuditDatable datatable
 */

const OrganisationAuditDatatable = ({ requestParams }: OrganisationAuditDatableProps) => {
    const [tableFilters, setTableFilters] = useState<TableFilterOptions>({
        search: '',
        sortField: 'created_at',
        sortOrder: SortOrder.DESC,
        rows: DT_ROWS_NO,
        first: 0
    });
    const updateAuditFilterHandler = useUpdateSearchParams();
    const {
        pageQuery,
        pageSizeQuery,
        sortOrderQuery,
        sortFieldQuery,
    } = useGetAuditFiltersQuery();

    const customersRequest = useMemo<GetActivityLogListRequest>(() => {

        return {
            ...requestParams,
            sort_by: sortFieldQuery ?? undefined,
            sort_order: Number(sortOrderQuery) === SortOrder.ASC ? SortOrderAudit.Asc : tableFilters.sortOrder === SortOrder.DESC ? SortOrderAudit.Desc : undefined,
            page: pageQuery ? parseInt(pageQuery) : 1,
            limit: pageSizeQuery ? parseInt(pageSizeQuery) : 10,
        };
    }, [requestParams, sortOrderQuery, sortFieldQuery, tableFilters.sortOrder, pageQuery, pageSizeQuery]);

    const {
        data: usersData,
        isLoading: customersLoading
    } = useGetActivityLog({
        ...customersRequest
    });

    const onPagination = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            if (draft.rows !== e.rows) {
                draft.first = 0;
            } else {
                draft.first = e.first;
            }
            draft.rows = e.rows;
        }));
        updateAuditFilterHandler(AuditFilterType.Page, ((e.first / e.rows) + 1).toString());
        updateAuditFilterHandler(AuditFilterType.PageSize, e.rows.toString());
    };

    const onSort = (e: DataTableStateEvent) => {
        updateAuditFilterHandler(AuditFilterType.SortField, e.sortField);
        updateAuditFilterHandler(AuditFilterType.SortOrder, e.sortOrder ?? SortOrder.UNSORTED);
        setTableFilters(produce(draft => {
            draft.sortField = e.sortField;
            draft.sortOrder = e.sortOrder ?? SortOrder.UNSORTED;
        }));
    };


    /**
     * Actions column template
     *
     * @param {Audit} audit object containing row data
     */


    const ActionsBodyTemplate = (audit: ActivityLog) => {
        const [visible, setVisible] = useState(false);
        return (
            <div className="flex gap-2">
                <Button
                    onClick={() => setVisible(true)}
                    label='View'
                />
                <Dialog header="Information about Audit" draggable={false} resizable={false} visible={visible} style={{ width: '50vw' }} onHide={() => {if (!visible) return; setVisible(false); }}>
                    <AuditPreview audit={audit as ComplaintAudit}  />
                </Dialog>
            </div>
        );
    };



    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'Date Time',
            sortable: true,
            field: 'created_at',
            body: (activityData) => {
                return <>
                    {formatToUKDate(activityData.created_at)}
                </>;

            }
        },
        {
            label: 'User Name',
            field: 'user.name.display_name',

            body: (activityData) => {
                return <>
                    {activityData.user?.display_name ?? 'N/A'}
                </>;

            }
        },
        {
            label: 'User Roles',
            body: (activityData) => {
                return <div className="flex flex-column gap-1">
                    <p>{activityData?.user?.main_role ? formatRole(activityData?.user?.main_role) : 'N/A'}</p>
                    {activityData?.user?.additional_roles?.map((el: string) => <Tag key={el} value={formatRole(el)} severity='info'/>)}
                </div>;
            }
        },
        {
            label: 'Complaint',
            body: (activityData) => {
                return <>
                    {activityData.complaint?.id ?? 'N/A'}
                </>;

            }
        },
        {
            label: 'Complaint Status',
            body: (activityData) => {
                return <>
                    {activityData.complaint?.state ? getLabelForState(activityData.complaint?.state) : 'N/A'}
                </>;

            }
        },
        {
            label: 'Type',
            body: (activityData) => {
                return <>
                    {activityData.log_name ? getLabelForType(activityData.log_name) : "N/A"}
                </>;

            }
        },
        {
            label: 'Action',
            body: (activityData) => {
                return <>
                    {activityData.event ? getLabelForLogsEvent(activityData.event) : 'N/A'}
                </>;

            }
        },
        {
            label: 'Description',
            body: (activityData) => {
                return <>
                    {activityData.description ?? 'N/A'}
                </>;

            }
        },
        {
            label: '',
            body: ActionsBodyTemplate
        }
    ];

    const first = pageSizeQuery && pageQuery ?  Number(pageSizeQuery)*Number(pageQuery) - 10 : 0;
    const rows = pageSizeQuery ? Number(pageSizeQuery) : 10;

    return (
        <StyledWrap>
            <div className="datatable-container">
                <DataTable
                    lazy
                    loading={customersLoading}
                    emptyMessage="No customers found."
                    value={usersData?.data}
                    totalRecords={usersData?.meta.total}
                    paginator
                    rows={rows}
                    rowsPerPageOptions={[10, 20, 50]}
                    first={first}
                    pt={dataTablePtOptions}
                    sortIcon={(options) => {
                        return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted}/>;
                    }}
                    sortField={sortFieldQuery || 'created_at'}
                    sortOrder={getSortOrder(sortOrderQuery)}
                    onPage={e => {
                        onPagination(e);
                    }}
                    onSort={e => {
                        onSort(e);
                    }}
                >
                    {tableColumns.map((column, index) =>
                        <Column
                            key={`customers-column-${index}`}
                            field={column.field}
                            body={column.body}
                            header={column.label}
                            sortable={column.sortable}
                            sortField={column.sortField}
                            headerTooltip={column.headerTooltip}
                        />
                    )}
                </DataTable>
            </div>
        </StyledWrap>
    );
};

export default OrganisationAuditDatatable;
