/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Complaint,
  ComplaintSortBy,
  GetComplaints200Response,
  SortOrder,
  StoreComplaintRequest,
  UpdateComplaintAssignRequest,
  UpdateComplaintRequest,
} from '../models/index';
import {
    ComplaintFromJSON,
    ComplaintToJSON,
    ComplaintSortByFromJSON,
    ComplaintSortByToJSON,
    GetComplaints200ResponseFromJSON,
    GetComplaints200ResponseToJSON,
    SortOrderFromJSON,
    SortOrderToJSON,
    StoreComplaintRequestFromJSON,
    StoreComplaintRequestToJSON,
    UpdateComplaintAssignRequestFromJSON,
    UpdateComplaintAssignRequestToJSON,
    UpdateComplaintRequestFromJSON,
    UpdateComplaintRequestToJSON,
} from '../models/index';

export interface CreateComplaintRequest {
    StoreComplaintRequest?: StoreComplaintRequest;
}

export interface DeleteComplaintRequest {
    complaint_id: number;
}

export interface EscalateComplaintRequest {
    complaint_id: number;
}

export interface GetComplaintRequest {
    complaint_id: number;
}

export interface GetComplaintArchiveRequest {
    complaint_id: number;
}

export interface GetComplaintsRequest {
    search?: string;
    sort_by?: ComplaintSortBy;
    sort_order?: SortOrder;
    from?: string;
    to?: string;
    states?: Array<GetComplaintsStatesEnum>;
    page?: number;
    limit?: number;
    is_less_3day?: boolean;
    is_for_today?: boolean;
    organisation_id?: number;
    customer_id?: number;
    root_cause_id?: number;
    assigned_to_id?: number;
    product_id?: number;
    only_trashed?: boolean;
}

export interface RestoreComplaintRequest {
    complaint_id: number;
}

export interface UpdateComplaintOperationRequest {
    complaint_id: number;
    UpdateComplaintRequest?: UpdateComplaintRequest;
}

export interface UpdateComplaintAssigmentRequest {
    complaint_id: number;
    UpdateComplaintAssignRequest?: UpdateComplaintAssignRequest;
}

/**
 * 
 */
export class ComplaintsApi extends runtime.BaseAPI {

    /**
     * Create complaint
     */
    async createComplaintRaw(requestParameters: CreateComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Complaint>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoreComplaintRequestToJSON(requestParameters.StoreComplaintRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintFromJSON(jsonValue));
    }

    /**
     * Create complaint
     */
    async createComplaint(requestParameters: CreateComplaintRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Complaint> {
        const response = await this.createComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete complaint
     */
    async deleteComplaintRaw(requestParameters: DeleteComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling deleteComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete complaint
     */
    async deleteComplaint(requestParameters: DeleteComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteComplaintRaw(requestParameters, initOverrides);
    }

    /**
     * Escalate complaint assigment
     */
    async escalateComplaintRaw(requestParameters: EscalateComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Complaint>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling escalateComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/escalate`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintFromJSON(jsonValue));
    }

    /**
     * Escalate complaint assigment
     */
    async escalateComplaint(requestParameters: EscalateComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Complaint> {
        const response = await this.escalateComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get complaint
     */
    async getComplaintRaw(requestParameters: GetComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Complaint>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintFromJSON(jsonValue));
    }

    /**
     * Get complaint
     */
    async getComplaint(requestParameters: GetComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Complaint> {
        const response = await this.getComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download Complaint Archive
     */
    async getComplaintArchiveRaw(requestParameters: GetComplaintArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getComplaintArchive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/download-archive`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download Complaint Archive
     */
    async getComplaintArchive(requestParameters: GetComplaintArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getComplaintArchiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of Complaints
     */
    async getComplaintsRaw(requestParameters: GetComplaintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComplaints200Response>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort_by !== undefined) {
            queryParameters['sort_by'] = requestParameters.sort_by;
        }

        if (requestParameters.sort_order !== undefined) {
            queryParameters['sort_order'] = requestParameters.sort_order;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.states) {
            queryParameters['states[]'] = requestParameters.states;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.is_less_3day !== undefined) {
            queryParameters['is_less_3day'] = requestParameters.is_less_3day;
        }

        if (requestParameters.is_for_today !== undefined) {
            queryParameters['is_for_today'] = requestParameters.is_for_today;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        if (requestParameters.customer_id !== undefined) {
            queryParameters['customer_id'] = requestParameters.customer_id;
        }

        if (requestParameters.root_cause_id !== undefined) {
            queryParameters['root_cause_id'] = requestParameters.root_cause_id;
        }

        if (requestParameters.assigned_to_id !== undefined) {
            queryParameters['assigned_to_id'] = requestParameters.assigned_to_id;
        }

        if (requestParameters.product_id !== undefined) {
            queryParameters['product_id'] = requestParameters.product_id;
        }

        if (requestParameters.only_trashed !== undefined) {
            queryParameters['only_trashed'] = requestParameters.only_trashed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComplaints200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of Complaints
     */
    async getComplaints(requestParameters: GetComplaintsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComplaints200Response> {
        const response = await this.getComplaintsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Restore complaint
     */
    async restoreComplaintRaw(requestParameters: RestoreComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Complaint>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling restoreComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/restore`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintFromJSON(jsonValue));
    }

    /**
     * Restore complaint
     */
    async restoreComplaint(requestParameters: RestoreComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Complaint> {
        const response = await this.restoreComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update complaint
     */
    async updateComplaintRaw(requestParameters: UpdateComplaintOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Complaint>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updateComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateComplaintRequestToJSON(requestParameters.UpdateComplaintRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintFromJSON(jsonValue));
    }

    /**
     * Update complaint
     */
    async updateComplaint(requestParameters: UpdateComplaintOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Complaint> {
        const response = await this.updateComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assign or Unassign (set to null assigned_to_id) Complaint
     */
    async updateComplaintAssigmentRaw(requestParameters: UpdateComplaintAssigmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Complaint>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updateComplaintAssigment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/assigment`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateComplaintAssignRequestToJSON(requestParameters.UpdateComplaintAssignRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintFromJSON(jsonValue));
    }

    /**
     * Assign or Unassign (set to null assigned_to_id) Complaint
     */
    async updateComplaintAssigment(requestParameters: UpdateComplaintAssigmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Complaint> {
        const response = await this.updateComplaintAssigmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetComplaintsStatesEnum = {
    Created: 'App\\Models\\ComplaintState\\Created',
    Investigated: 'App\\Models\\ComplaintState\\Investigated',
    Resolved: 'App\\Models\\ComplaintState\\Resolved',
    Closed: 'App\\Models\\ComplaintState\\Closed'
} as const;
export type GetComplaintsStatesEnum = typeof GetComplaintsStatesEnum[keyof typeof GetComplaintsStatesEnum];
