import React from 'react';
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import UserSelect from "@/components/Core/Form/Selector/UserSelect";
import BaseDropdown from "@/components/Core/Form/BaseDropdown";
import { SelectItem } from "primereact/selectitem";
import { ActivityLogEvent, ActivityLogName, ComplaintStateEnum, Customer, User } from "@/stub";
import { Button } from "primereact/button";
import { useGetUser } from "@/Service/Api/ApiHooks/Users/useGetUser";
import { useSearchParams } from "react-router-dom";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { useUpdateSearchParams } from "@/Hooks/useUpdateSearchParams";
import useGetAuditFiltersQuery, { AuditFilterType } from "@/Hooks/useGetAuditFiltersQuery";
import { AuditFilterOptions } from "@/Enums/AuditFilterOptions";
import BaseComplaintSelect from "@/components/Core/Form/Selector/BaseComplaintSelect";
import { useGetComplaint } from "@/Service/Api/ApiHooks/Complaint/useGetComplaint";

export type AuditFilterOption = {
    type: AuditFilterOptions,
    value: string
};

export type AuditFiltersListProps = {
    className?: string
    filterOptions: AuditFilterOption[]
};

export type TableFilters = {
    date_range: Date[],
    customer?: Customer,
    owner?: User,
    status?: ComplaintStateEnum
};

export const getLabelForType = (state: ActivityLogName): string => {
    const labelMap = {
        [ActivityLogName.Old]: 'Complaint Common',
        [ActivityLogName.ComplaintAction]: 'Complaint Action',
        [ActivityLogName.ComplaintContact]: 'Complaint Contact',
        [ActivityLogName.ComplaintFos]: 'Complaint FOS',
        [ActivityLogName.ComplaintCustomer]: 'Complaint Customer',
        [ActivityLogName.ComplaintPoint]: 'Complaint Point',
        [ActivityLogName.Complaint]: 'Complaint',
        [ActivityLogName.ComplaintDecision]: 'Complaint Decision',
        [ActivityLogName.ComplaintLetter]: 'Complaint Letter',
        [ActivityLogName.ComplaintDocument]: 'Complaint Document',
        [ActivityLogName.ComplaintEvidence]: 'Complaint Evidence',
        [ActivityLogName.ComplaintOutcomes]: 'Complaint Outcomes'
    };

    return labelMap[state] || 'Unknown State';
};

const excludedLogs: string | string[] = [];

const availableLogs = (): SelectItem[] => {
    return Object.entries(ActivityLogName)
        .filter(([, value]) => !excludedLogs.includes(value))
        .map(([key, value]) => ({
            value: value,
            label: getLabelForType(value) || key
        }));
};

export const getLabelForLogsEvent = (state: ActivityLogEvent): string => {
    const labelMap = {
        [ActivityLogEvent.Closed]: 'Closed',
        [ActivityLogEvent.Created]: 'Created',
        [ActivityLogEvent.Updated]: 'Updated',
        [ActivityLogEvent.Deleted]: 'Deleted',
    };

    return labelMap[state] || 'Unknown State';
};

const excludedLogsEvent: string | string[] = [];

const availableLogsEvent = (): SelectItem[] => {
    return Object.entries(ActivityLogEvent)
        .filter(([, value]) => !excludedLogsEvent.includes(value))
        .map(([key, value]) => ({
            value: value,
            label: getLabelForLogsEvent(value) || key
        }));
};


const AuditFilterList: React.FC<AuditFiltersListProps> = ({
    className,
    filterOptions
}: AuditFiltersListProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const resetTableFilters = () => {
        searchParams.delete(AuditFilterType.OwnerId);
        searchParams.delete(AuditFilterType.ComplaintId);
        searchParams.delete(AuditFilterType.DateRangeTo);
        searchParams.delete(AuditFilterType.DateRangeFrom);
        searchParams.delete(AuditFilterType.Search);
        searchParams.delete(AuditFilterType.Page);
        searchParams.delete(AuditFilterType.PageSize);
        searchParams.delete(AuditFilterType.SortField);
        searchParams.delete(AuditFilterType.SortOrder);
        searchParams.delete(AuditFilterType.Type);
        searchParams.delete(AuditFilterType.Action);
        setSearchParams(searchParams, { replace: true });
    };

    const updateFilterHandler = useUpdateSearchParams();
    const {
        ownerQuery,
        dataRangeQueryTo,
        dataRangeQueryFrom,
        typeQuery,
        complaintIdQuery,
        actionQuery,
    } = useGetAuditFiltersQuery();


    const { data: user } = useGetUser({
        requestParams: {
            user_id: Number(ownerQuery)
        },
        enabled: !!ownerQuery
    });

    const { data: complaint } = useGetComplaint({
        requestParameters: {
            complaint_id: Number(complaintIdQuery)
        },
        enabled: !!complaintIdQuery
    });



    return (
        <div className="flex justify-content-between">
            <div className={className}>
                {
                    filterOptions.map(
                        option => {
                            switch (option.type) {
                                case AuditFilterOptions.Owner:
                                    return (
                                        <>
                                            <UserSelect
                                                key={option.type}
                                                value={user}
                                                placeholder={AuditFilterOptions.Owner}
                                                onClear={() => {
                                                    searchParams.delete(AuditFilterType.OwnerId);
                                                    setSearchParams(searchParams, { replace: true });
                                                }}
                                                onSelect={(event) => {
                                                    updateFilterHandler(AuditFilterType.OwnerId, event.value.id);
                                                }}
                                            />

                                        </>
                                    );
                                case AuditFilterOptions.Complaint:
                                    return (
                                        <>
                                            <BaseComplaintSelect
                                                key={option.type}
                                                value={complaint}
                                                placeholder={AuditFilterOptions.Complaint}
                                                onClear={() => {
                                                    searchParams.delete(AuditFilterType.ComplaintId);
                                                    setSearchParams(searchParams, { replace: true });

                                                }}
                                                onSelect={(event) => {
                                                    updateFilterHandler(AuditFilterType.ComplaintId, event.value.id);
                                                }}
                                            />

                                        </>
                                    );
                                case AuditFilterOptions.DateRange:
                                    const value = dataRangeQueryFrom || dataRangeQueryTo ? [!!dataRangeQueryFrom ? new Date(dataRangeQueryFrom) : new Date(), dataRangeQueryTo ? new Date(dataRangeQueryTo) : null] : [];
                                    return <BaseCalendar
                                        key={option.type}
                                        value={value}
                                        selectionMode='range'
                                        dateFormat='dd/mm/yy'
                                        placeholder={AuditFilterOptions.DateRange}
                                        showButtonBar
                                        selectOtherMonths
                                        readOnlyInput
                                        hideOnRangeSelection
                                        onClearButtonClick={() => {
                                            searchParams.delete(AuditFilterType.DateRangeFrom);
                                            searchParams.delete(AuditFilterType.DateRangeTo);
                                            setSearchParams(searchParams, { replace: true });
                                        }}
                                        onChange={(event) => {
                                            if (event.value === null) {
                                                searchParams.delete(AuditFilterType.DateRangeFrom);
                                                searchParams.delete(AuditFilterType.DateRangeTo);
                                                setSearchParams(searchParams, { replace: true });
                                            }
                                            updateFilterHandler(AuditFilterType.DateRangeFrom, formatToApiDate((event.value as Date[])[0]));
                                            updateFilterHandler(AuditFilterType.DateRangeTo, formatToApiDate((event.value as Date[])[1]));
                                        }
                                        }

                                    />;

                                case AuditFilterOptions.Type:
                                    return <BaseDropdown
                                        key={option.type}
                                        value={typeQuery}
                                        options={availableLogs()}
                                        showClear
                                        placeholder={AuditFilterOptions.Type}
                                        onChange={(event) => {
                                            updateFilterHandler(AuditFilterType.Type, event.value);
                                        }}
                                    />;
                                case AuditFilterOptions.Action:
                                    return <BaseDropdown
                                        key={option.type}
                                        value={actionQuery}
                                        options={availableLogsEvent()}
                                        showClear
                                        placeholder={AuditFilterOptions.Action}
                                        onChange={(event) => {
                                            updateFilterHandler(AuditFilterType.Action, event.value);
                                        }}
                                    />;

                            }
                        })
                }
            </div>
            <div>
                <Button
                    label="Reset"
                    onClick={resetTableFilters}
                />
            </div>
        </div>
    );
};

export default AuditFilterList;
